// import React from "react";
import { Link } from "react-router-dom";
import PortfolioSec from "./PortfolioSec";
import React, { useEffect, useRef } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Helmet } from 'react-helmet';

export default function Home() {

    useEffect(() => {
        // Initialize AOS
        AOS.init();
    }, []);
    var settings = {
        dots: false,
        infinite: true,
        autoplay: true,
        speed: 2000,
        slidesToShow: 1,
        slidesToScroll: 1,
    };

    const counterRowRef = useRef(null);

    useEffect(() => {
        const options = {
            root: null,
            rootMargin: '0px',
            threshold: 0.1 // Adjust threshold as needed
        };

        const animateCounter = (element, start, end, duration) => {
            let startTime = null;

            const step = (currentTime) => {
                if (!startTime) startTime = currentTime;
                const progress = Math.min((currentTime - startTime) / duration, 1);
                element.innerText = Math.floor(progress * (end - start) + start);
                if (progress < 1) {
                    window.requestAnimationFrame(step);
                }
            };

            window.requestAnimationFrame(step);
        };

        const handleIntersect = (entries, observer) => {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    console.log('Section is visible');
                    const counters = entry.target.querySelectorAll('.counter h2 span');
                    counters.forEach(counter => {
                        const target = parseInt(counter.innerText, 10);
                        animateCounter(counter, 0, target, 2000);
                    });
                    observer.unobserve(entry.target);
                }
            });
        };

        const observer = new IntersectionObserver(handleIntersect, options);

        if (counterRowRef.current) {
            observer.observe(counterRowRef.current);
        }

        return () => {
            if (counterRowRef.current) {
                observer.unobserve(counterRowRef.current);
            }
        };
    }, []);

    var clients = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 6,
        slidesToScroll: 1,
        infinite: true,
        autoplay: true,
        speed: 2000,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    initialSlide: 1
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1
                }
            }
        ]
    };

    return (
        <>
            <Helmet>
                <title>IT POWER SYSTEMS - Home</title>
                <meta name="description" content="
                Empower your business with IT Power Systems, your trusted partner for cutting-edge digital solutions. 
                Specializing in custom software development, mobile app creation, and scalable ecommerce platforms, we help startups and businesses excel in the digital age. 
                Contact us to elevate your online presence and drive growth."/>
            </Helmet>
            <section className="main-banner">
                <Slider {...settings}>
                    <div className="position-relative">
                        <div className="banne-content slide-1">
                            <div className="container">
                                <div className="row banner-b justify-content-center">
                                    <div className="col-xxl-8 col-xl-8 col-lg-8 col-md-12 col-sm-12 col-12 position-relative">
                                        <h1 className="theme-h3" data-aos="fade-right" data-aos-duration="3000">
                                            Web Development That Boosts Conversions
                                        </h1>
                                        <p data-aos="fade-right" data-aos-duration="2500">Elevate your brand with custom WordPress development and cutting-edge website design that drives measurable results.</p>
                                        <div className="btn-bannner" data-aos="fade-right" data-aos-duration="2000">
                                            <Link className="theme-btn" to="/contact-us">Start Project</Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="position-relative">
                        <div className="banne-content slide-2">
                            <div className="container">
                                <div className="row banner-b justify-content-center">
                                    <div className="col-xxl-8 col-xl-8 col-lg-8 col-md-12 col-sm-12 col-12 position-relative">
                                        <h1 className="theme-h3" data-aos="fade-right" data-aos-duration="3000">
                                            Custom Software Development for the Future
                                        </h1>
                                        <p data-aos="fade-right" data-aos-duration="2000">Lead the market with our expert custom software development services designed to meet your unique business needs</p>
                                        <div className="btn-bannner">
                                            <Link className="theme-btn" to="/contact-us">Start Project</Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="position-relative">
                        <div className="banne-content slide-3">
                            <div className="container">
                                <div className="row banner-b justify-content-center">
                                    <div className="col-xxl-8 col-xl-8 col-lg-8 col-md-12 col-sm-12 col-12 position-relative">
                                        <h1 className="theme-h3" data-aos="fade-right" data-aos-duration="3000">
                                            Mobile App Development That Engages Users
                                        </h1>
                                        <p data-aos="fade-right" data-aos-duration="2000">Transform your digital presence with innovative mobile app solutions that connect and captivate your audience</p>
                                        <div className="btn-bannner">
                                            <Link className="theme-btn" to="/contact-us">Start Project</Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Slider>
            </section>



            <section className="about-sec">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12 " data-aos="fade-right"
                            data-aos-duration="3000">
                            <div className="img-p">
                                <div className="img-lap">
                                    <img className="img-fluid" src="assets/images/home-image-01.jpg" alt="About Us" />
                                </div>
                                {/* <div className="img-about">
                            <img className="img-fluid" src="assets/images/software-development-pic.png" alt="Portfolio"/>
                        </div> */}
                            </div>
                        </div>
                        <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12 ps-lg-5">
                            <div className="content" >
                                <h2 className="theme-h2" data-aos="fade-left" data-aos-duration="3000">Driving Business Growth with Cutting-Edge Technology</h2>
                                <p data-aos="fade-left" data-aos-duration="2500">At IT Power Systems, we empower businesses and startups to thrive in the digital age with advanced technology solutions and strategic expertise. Our team of seasoned digital experts delivers innovative, custom solutions designed to accelerate growth and optimize efficiency.</p>
                                <div className="box" data-aos="fade-left" data-aos-duration="2000">
                                    <div className="row gy-4">
                                        <div className="col-xl-6 col-lg-6 col-md-6 col-12">
                                            <div className="card">
                                                <h1 style={{ fontSize: '16px', textAlign: 'center', fontWeight: 'bold' }}>Industry Leaders in Digital Transformation:</h1>
                                                <p>With years of experience, our experts are dedicated to guiding your business through the complexities of the digital landscape.</p>
                                                <span><img className="img-fluid" src="assets/images/expert-icon.svg" /></span>
                                            </div>
                                        </div>
                                        <div className="col-xl-6 col-lg-6 col-md-6 col-12">
                                            <div className="card">
                                                <h1 style={{ fontSize: '16px', textAlign: 'center', fontWeight: 'bold' }}>Customized Solutions for Your Success:</h1>
                                                <p>We tailor our services to align with your specific business needs and objectives, ensuring that your digital strategy is both effective and scalable.</p>
                                                <span><img className="img-fluid" src="assets/images/tailored-icon.svg" /></span>
                                            </div>
                                        </div>
                                        <div className="col-xl-6 col-lg-6 col-md-6 col-12">
                                            <div className="card">
                                                <h1 style={{ fontSize: '16px', textAlign: 'center', fontWeight: 'bold' }}>Collaborative Approach to Meet Your Goals:</h1>
                                                <p>We work closely with you to understand your unique challenges and aspirations, ensuring our solutions are perfectly aligned with your business goals.</p>
                                                <span><img className="img-fluid" src="assets/images/goal-icon.svg" /></span>
                                            </div>
                                        </div>
                                        <div className="col-xl-6 col-lg-6 col-md-6 col-12">
                                            <div className="card">
                                                <h1 style={{ fontSize: '16px', textAlign: 'center', fontWeight: 'bold' }}>Innovative and High-Quality Results:</h1>
                                                <p>Our commitment to excellence drives us to create solutions that not only meet but exceed expectations, delivering measurable growth and enhanced efficiency.</p>
                                                <span><img className="img-fluid" src="assets/images/growth-icon.svg" /></span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="counter-sec">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-lg-10 col-md-11 col-12">
                            <div className="counter-row gx-0" ref={counterRowRef}>
                                <div className="counter" data-aos="fade-left" data-aos-duration="3000">
                                    <h2>
                                        <span>6</span>+
                                    </h2>
                                    <h4>Years of Experience</h4>
                                </div>
                                <div className="counter" data-aos="fade-down" data-aos-duration="3000">
                                    <h2>
                                        <span>730</span>+
                                    </h2>
                                    <h4>successfully Project Done</h4>
                                </div>
                                <div className="counter" data-aos="fade-left" data-aos-duration="3000">
                                    <h2>
                                        <span>198</span>+
                                    </h2>
                                    <h4>Satisfied Happy Clients</h4>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="services-sec">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-lg-8">
                            <div className="title" data-aos="fade-down" data-aos-duration="3000">
                                <h2>
                                    Our Expert IT Services
                                </h2>
                            </div>
                        </div>
                    </div>
                    <div className="row gy-4 justify-content-center">
                        {/* <div className="col-xl-4 col-lg-4 col-md-6 col-12 panel">
                            <div className="card h-100">
                                <span>
                                    <img className="img-fluid" src="assets/images/services-icons/content-writing.svg" />
                                </span>
                                <div className="content">
                                    <h3>Content Writing:</h3>
                                    <p>
                                       Crafting compelling content that tells your story and engages your audience.
                                    </p>
                                </div>
                            </div>
                        </div> */}
                        <div className="col-xl-4 col-lg-4 col-md-6 col-12 panel">
                            <div className="card h-100" data-aos="fade-right" data-aos-duration="3000">
                                <span>
                                    <img className="img-fluid" src="assets/images/services-icons/digital-marketing.svg" />
                                </span>
                                <Link to="/digital-marketing-service">
                                    <div className="content">
                                        <h3>Digital Marketing:</h3>
                                        <p>
                                            Boost your online presence with digital marketing strategies designed to increase visibility, drive traffic, and convert leads. Leverage SEO, content marketing, and PPC to grow your business.
                                        </p>
                                    </div>
                                </Link>
                            </div>
                        </div>
                        <div className="col-xl-4 col-lg-4 col-md-6 col-12 panel">
                            <div className="card h-100" data-aos="fade-down" data-aos-duration="3000">
                                <span>
                                    <img className="img-fluid" src="assets/images/services-icons/ecommerce-solutions.svg" />
                                </span>
                                <Link to="/ecommerce-web">
                                    <div className="content">
                                        <h3>E-Commerce Solutions:</h3>
                                        <p>
                                            Optimize your sales with our e-commerce website development services, creating seamless and scalable online stores. Enhance customer experience with tailored e-commerce platforms.
                                        </p>
                                    </div>
                                </Link>
                            </div>
                        </div>
                        <div className="col-xl-4 col-lg-4 col-md-6 col-12 panel">
                            <div className="card h-100" data-aos="fade-left" data-aos-duration="3000">
                                <span>
                                    <img className="img-fluid" src="assets/images/services-icons/graphic-desgin.svg" />
                                </span>
                                <Link to="/graphic-design">
                                    <div className="content">
                                        <h3>Graphic Design:</h3>
                                        <p>
                                            Create a memorable brand with our professional graphic design services. We specialize in crafting visually stunning logo designs, marketing materials, and digital assets that resonate with your audience.
                                        </p>
                                    </div>
                                </Link>
                            </div>
                        </div>
                        <div className="col-xl-4 col-lg-4 col-md-6 col-12 panel">
                            <div className="card h-100" data-aos="fade-right" data-aos-duration="3000">
                                <span>
                                    <img className="img-fluid" src="assets/images/services-icons/mobile-application-development.svg" />
                                </span>
                                <Link to="/mobile-app-service">
                                    <div className="content">
                                        <h3>Mobile App Development:</h3>
                                        <p>
                                            Engage and retain your customers with custom mobile app development tailored to your business. Our apps are designed for optimal performance and user experience on iOS and Android platforms.
                                        </p>
                                    </div>
                                </Link>
                            </div>
                        </div>
                        <div className="col-xl-4 col-lg-4 col-md-6 col-12 panel">
                            <div className="card h-100" data-aos="zoom-in" data-aos-duration="3000">
                                <span>
                                    <img className="img-fluid" src="assets/images/services-icons/seo.svg" />
                                </span>
                                <Link to="/search-engine-optimization">
                                    <div className="content">
                                        <h3>SEO:</h3>
                                        <p>
                                            Dominate search engine rankings with our expert SEO services. From keyword optimization to link building, we ensure your website achieves higher visibility and drives organic traffic.
                                        </p>
                                    </div>
                                </Link>
                            </div>
                        </div>
                        <div className="col-xl-4 col-lg-4 col-md-6 col-12 panel" >
                            <div className="card h-100" data-aos="fade-left" data-aos-duration="3000">
                                <span>
                                    <img className="img-fluid" src="assets/images/services-icons/social-media-marketing.svg" />
                                </span>
                                <Link to="/social-media-marketing">
                                    <div className="content">
                                        <h3>Social Media Marketing:</h3>
                                        <p>
                                            Elevate your brand's presence with strategic social media marketing campaigns. Our expertise in social media management and content creation helps you connect with your audience on platforms like Facebook, Instagram, and LinkedIn.
                                        </p>
                                    </div>
                                </Link>
                            </div>
                        </div>
                        <div className="col-xl-4 col-lg-4 col-md-6 col-12 panel">
                            <div className="card h-100" data-aos="fade-right" data-aos-duration="3000">
                                <span>
                                    <img className="img-fluid" src="assets/images/services-icons/software-development.svg" />
                                </span>
                                <Link to="/software-service">
                                    <div className="content">
                                        <h3>Software Development:</h3>
                                        <p>
                                            Transform your business operations with custom software development that meets your specific needs. Our solutions are built to ensure scalability, performance, and security.
                                        </p>
                                    </div>
                                </Link>
                            </div>
                        </div>
                        <div className="col-xl-4 col-lg-4 col-md-6 col-12 panel">
                            <div className="card h-100" data-aos="fade-up" data-aos-duration="3000">
                                <span>
                                    <img className="img-fluid" src="assets/images/services-icons/uiux-design.svg" />
                                </span>
                                <Link to="/uiux-design">
                                    <div className="content">
                                        <h3>UI/UX Design:</h3>
                                        <p>
                                            Enhance user satisfaction with our UI/UX design services. We create intuitive and engaging user interfaces that improve usability and overall experience, leading to higher customer retention.
                                        </p>
                                    </div>
                                </Link>
                            </div>
                        </div>
                        <div className="col-xl-4 col-lg-4 col-md-6 col-12 panel">
                            <div className="card h-100" data-aos="fade-left" data-aos-duration="3000">
                                <span>
                                    <img className="img-fluid" src="assets/images/services-icons/website-development.svg" />
                                </span>
                                <Link to="/web-development">
                                    <div className="content">
                                        <h3>Website Development:</h3>
                                        <p>
                                            Build a powerful online presence with our website development services. We create responsive, user-friendly websites optimized for performance, SEO, and conversions.
                                        </p>
                                    </div>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="about-sec abot-ser">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12 " data-aos="fade-right"
                            data-aos-duration="3000">
                            <div className="img-p">
                                <div className="img-lap" data-aos="fade-right" data-aos-duration="3000">
                                    <img className="img-fluid" src="assets/images/about-com.png" alt="About Us" />
                                </div>
                                {/* <div className="img-about">
                            <img className="img-fluid" src="assets/images/software-development-pic.png" alt="Portfolio"/>
                        </div> */}
                            </div>
                        </div>
                        <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12 ps-lg-5">
                            <div className="content">
                                <h2 className="theme-h2" data-aos="fade-left" data-aos-duration="3000">Transform Your Business with Extraordinary Mobile App Development</h2>
                                <p data-aos="fade-left" data-aos-duration="2500">At IT Power Systems, we bring your visionary ideas to life with our custom mobile app development services. Our team of expert developers and designers creates innovative, user-focused mobile apps that not only captivate but also deeply engage your audience.</p>
                                <p data-aos="fade-left" data-aos-duration="2500">Whether you're targeting iOS, Android, or both, we deliver apps with flawless performance and an exceptional user experience. Empower your business to excel in the mobile-first world with custom apps that surpass your expectations. Let's revolutionize your business with powerful mobile solutions.</p>
                                <p data-aos="fade-left" data-aos-duration="2500" style={{ fontWeight: 'bold' }}>Ready to get started? Let's create the future of your business today.</p>
                                <Link style={{ marginTop: '20px' }} className="theme-btn" to="/contact-us" data-aos="fade-left" data-aos-duration="2000">Let's Get Started</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <PortfolioSec />

            <section className="our-clients-sec">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-lg-11">
                            <div className="title">
                                <h4>Trusted and loved by the <span> 150+ </span> best companies</h4>
                                <Slider {...clients}>
                                    <div>
                                        <div className="cli-logo">
                                            <img className="img-fluid" src="assets/images/clients/1.png" />
                                        </div>
                                    </div>
                                    <div>
                                        <div className="cli-logo">
                                            <img className="img-fluid" src="assets/images/clients/2.png" />
                                        </div>
                                    </div>
                                    <div>
                                        <div className="cli-logo">
                                            <img className="img-fluid" src="assets/images/clients/3.png" />
                                        </div>
                                    </div>
                                    <div>
                                        <div className="cli-logo">
                                            <img className="img-fluid" src="assets/images/clients/4.png" />
                                        </div>
                                    </div>
                                    <div>
                                        <div className="cli-logo">
                                            <img className="img-fluid" src="assets/images/clients/5.png" />
                                        </div>
                                    </div>
                                    <div>
                                        <div className="cli-logo">
                                            <img className="img-fluid" src="assets/images/clients/6.png" />
                                        </div>
                                    </div>
                                    <div>
                                        <div className="cli-logo">
                                            <img className="img-fluid" src="assets/images/clients/7.png" />
                                        </div>
                                    </div>
                                    <div>
                                        <div className="cli-logo">
                                            <img className="img-fluid" src="assets/images/clients/8.png" />
                                        </div>
                                    </div>
                                </Slider>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="faq-sec">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-lg-10">
                            <div className="title">
                                <h2 data-aos="fade-down" data-aos-duration="3000">Questions & Anwsers</h2>
                            </div>
                            <div className="accord" data-aos="zoom-in" data-aos-duration="3000">
                                <div className="accordion" id="accordionExample">
                                    <div className="accordion-item">
                                        <h2 className="accordion-header">
                                            <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                                What services does IT Power Systems offer?
                                            </button>
                                        </h2>
                                        <div id="collapseOne" className="accordion-collapse collapse show" data-bs-parent="#accordionExample">
                                            <div className="accordion-body">
                                                <p>IT Power Systems offers a wide range of digital solutions including Content Writing, Digital Marketing, E-Commerce Solutions, Graphic Design, Mobile App Development, SEO, Social Media Marketing, Software Development, UI/UX Design, and Website Development.</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="accordion-item">
                                        <h2 className="accordion-header">
                                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                                How long does it take to develop a website?
                                            </button>
                                        </h2>
                                        <div id="collapseTwo" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
                                            <div className="accordion-body">
                                                <p>The timeline for website development varies depending on the complexity and specific requirements of the project. Generally, it can take anywhere from a few weeks to a few months. We work closely with our clients to establish clear timelines and ensure timely delivery.</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="accordion-item">
                                        <h2 className="accordion-header">
                                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                                Can you customize a mobile app to meet my specific business needs?
                                            </button>
                                        </h2>
                                        <div id="collapseThree" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
                                            <div className="accordion-body">
                                                <p>Absolutely! We specialize in creating custom mobile apps tailored to your unique business requirements. Our team will work with you to understand your vision and develop an app that aligns with your goals and enhances user experience.</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="accordion-item">
                                        <h2 className="accordion-header">
                                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapsefive" aria-expanded="false" aria-controls="collapsefive">
                                                What is SEO and how can it benefit my business?
                                            </button>
                                        </h2>
                                        <div id="collapsefive" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
                                            <div className="accordion-body">
                                                <p>SEO, or Search Engine Optimization, is the practice of improving your website’s visibility on search engines like Google. Effective SEO strategies can increase your website traffic, improve your search rankings, and ultimately drive more leads and sales for your business.</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="accordion-item">
                                        <h2 className="accordion-header">
                                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapsesix" aria-expanded="false" aria-controls="collapsesix">
                                                How do you ensure the quality of your digital marketing services?
                                            </button>
                                        </h2>
                                        <div id="collapsesix" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
                                            <div className="accordion-body">
                                                <p>At IT Power Systems, we prioritize quality by employing a data-driven approach, leveraging the latest industry tools and techniques. Our team of experts continually monitors and optimizes campaigns to ensure they meet your business objectives and deliver the best possible results.</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="accordion-item">
                                        <h2 className="accordion-header">
                                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapsefour" aria-expanded="false" aria-controls="collapsefour">
                                                What kind of support can I expect after my website or app is launched?
                                            </button>
                                        </h2>
                                        <div id="collapsefour" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
                                            <div className="accordion-body">
                                                <p>We provide ongoing support and maintenance services to ensure your website or app continues to function smoothly. Our team is available to address any issues, perform updates, and make improvements as needed to keep your digital presence at its best.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="help">
                                <p>Don't find the answer? We can Help. <a href="/contact-us">Click here</a></p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="ctabanner" >
                <div className="container">
                    <div className="box-cta" data-aos="fade-up" data-aos-duration="3000">
                        <div className="row justify-content-center align-items-center">
                            <div className="col-12 col-sm-12 col-md-11 col-lg-12 col-xl-11 col-xxl-11">
                                <div className="row align-items-center">
                                    <div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 col-xxl-6">
                                        <div className="ctatext">
                                            <p>IT Power Systems,</p>
                                            <h3>Your business can reach its full potential.</h3>
                                        </div>
                                    </div>
                                    <div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 col-xxl-6">
                                        <div className="calllsit">
                                            <div className=" marginleft">
                                                <Link className="theme-btn" to="/contact-us">Get Started With Us today</Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}
