import React, { useEffect } from 'react';
import { Link } from "react-router-dom";
import AOS from 'aos';
import 'aos/dist/aos.css';

export default function Terms() {

    useEffect(() => {
        // Initialize AOS
        AOS.init();
    }, []);
  return (
    <>
     <section className="inner-banner">
        <div className="banner-content">
          <div className="container">
            <div className="row banner-b justify-content-center">
              <div className="col-xxl-7 col-xl-7 col-lg-7 col-md-7 col-sm-12 col-12 position-relative">
                <h2 className="theme-h2" data-aos="fade-right" data-aos-duration="3000"><span>Terms & Condition</span></h2>
                <p data-aos="fade-right" data-aos-duration="3000"> <Link to="/" >Home</Link> <i className="fa-solid fa-chevron-right"></i> Terms & Condition</p>
              </div>
            </div>
          </div>
        </div>
      </section>
    <section className="privacy-section all-section">
        <div className="container">
            <div className="row">
                <div className="col-md-10 mx-auto">
                    <div className="section-top text-center">
                        <h3 className="theme-h2">Terms & <span>Conditions</span></h3>
                        <p>By accessing our website “IT Power Systems”, you are agreeing to be bound by the following
                            Terms and Conditions of our website users. If you do not agree with any of the mentioned
                            terms, you are advised to close this site. The content shared on this website is protected:
                        </p>
                    </div>

                </div>
            </div>

            <div className="privacy-content">
                <span className="section-head">Access to Information</span>
                <p>Permission is granted to temporarily download one copy of the materials (information or information)
                    on IT Power Systems's website for personal, non-commercial transitory viewing only. This is the
                    grant of a license, not a transfer of title, and under this license, you may not:</p>
                <ul>
                    <li>
                        <p>Modify or copy the materials;</p>
                    </li>
                    <li>
                        <p>Use the materials for any commercial purpose, or any public display (commercial or
                            non-commercial);</p>
                    </li>
                    <li>
                        <p>Attempt to decompile or reverse engineer any information contained on IT Power Systems's
                            web site;</p>
                    </li>
                    <li>
                        <p>Remove any copyright or other proprietary notations from the materials; or</p>
                    </li>
                    <li>
                        <p>Transfer the materials to another person or "mirror" the materials on any other server.
                        </p>
                    </li>
                </ul>
                <p>This license shall automatically terminate if you violate any of these restrictions and may be
                    terminated by IT Power Systems at any time. Upon terminating your viewing of these materials or
                    upon the termination of this license, you must destroy any downloaded materials in your possession
                    whether in electronic or printed format.</p>
                <span className="section-head">Disclaimer</span>
                <p>The materials on IT Power Systems's website are provided "as is". IT Power Systems makes no
                    warranties, expressed or implied, and hereby disclaims and negates all other warranties, including
                    without limitation, implied warranties or conditions of merchantability, fitness for a particular
                    purpose, or non-infringement of intellectual property or other violation of rights. Further, Website
                    Nation LLC does not warrant or make any representations concerning the accuracy, likely results, or
                    reliability of the use of the materials on its Internet website or otherwise relating to such
                    materials or on any sites linked to this site.</p>
                <span className="section-head">Limitations</span>
                <p>In no event shall IT Power Systems or its suppliers be liable for any damages (including, without
                    limitation, damages for loss of data or profit, or due to business interruption,) arising out of the
                    use or inability to use the materials on IT Power Systems's Internet site, even if Website Nation
                    LLC or a IT Power Systems authorized representative has been notified orally or in writing of the
                    possibility of such damage. Because some jurisdictions do not allow limitations on implied
                    warranties, or limitations of liability for consequential or incidental damages, these limitations
                    may not apply to you. The materials appearing on IT Power Systems's website could include
                    technical, typographical, or photographic errors. IT Power Systems does not warrant that any of
                    the materials on its website are accurate, complete, or current. IT Power Systems may make changes
                    to the materials contained on its website at any time without notice. IT Power Systems does not,
                    however, make any commitment to update the materials.</p>
                <span className="section-head">Links</span>
                <p>IT Power Systems has not reviewed all of the sites linked to its Internet website and is not
                    responsible for the contents of any such linked site. The inclusion of any link does not imply
                    endorsement by the IT Power Systems of the site. Use of any such linked website is at the user's
                    own risk.</p>
                <span className="section-head">Website Modifications</span>
                <p>IT Power Systems may revise these terms of use for its website at any time without notice. By using
                    our website, you are agreeing to be bound by the then current version of these Terms and Conditions
                    of Use.</p>

            </div>
        </div>

    </section>
    </>
  );
}
