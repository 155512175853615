import React, { useEffect } from 'react';
import { Link } from "react-router-dom";
import service1 from '../assets/img/Services/graphicdesign.jpg';
import AOS from 'aos';
import 'aos/dist/aos.css';

export default function BlogDetail(){
      useEffect(() => {
        // Initialize AOS
        AOS.init();
    }, []);
    return(
      <>
      <section className="inner-banner">
        <div className="banner-content">
          <div className="container">
            <div className="row banner-b justify-content-center">
              <div className="col-xxl-7 col-xl-7 col-lg-7 col-md-7 col-sm-12 col-12 position-relative">
                <h2 className="theme-h2" data-aos="fade-right" data-aos-duration="3000"><span>Search Engine Optimization (SEO)</span></h2>
                <p data-aos="fade-right" data-aos-duration="3000"> <Link to="/" >Home</Link> <i className="fa-solid fa-chevron-right"></i> Search Engine Optimization (SEO)</p>
              </div>
            </div>
          </div>
        </div>
      </section>
    <section className="about-sec inner-about inner-serv blog-detail">
        <div className="container">
          <div className="row justify-content-center gy-4">
            <div className="col-xl-7 col-lg-7 col-md-12 col-sm-12 col-12" data-aos="fade-right" data-aos-duration="3000">
              <div className="content">
                <h2 className="theme-h2">Search Engine Optimization (SEO)</h2>
                <p> Search Engine Optimization (SEO) can significantly impact your business by improving your online visibility and driving organic traffic to your website. In this blog, we’ll explore how SEO has transformed businesses through various case studies and success stories.
                </p>
                <div>
                  <img className='img-fluid' src='assets/images/blogs/How-SEO-Can-Transform-Your-Business.jpg' />
                </div>
                
                  <p className="detail">
                      <span><i className="fas fa-user"></i> admin </span>
                      <span> <i className="fa-solid fa-calendar-days"></i> 06-26-2024</span> 
                  </p>
                  <h2 className="theme-h2">Search Engine Optimization (SEO)</h2>
                  <p><span className='spanbold'>Local Business Growth: </span>A local bakery saw a 200% increase in website traffic after implementing local SEO strategies, including optimizing Google My Business and creating localized content.</p>
                  <p><span className='spanbold'>E-Commerce Success: </span>An online retailer improved their search rankings by targeting long-tail keywords and optimizing product pages, resulting in a 150% increase in online sales.</p>
                  <p><span className='spanbold'>Service Industry Boom: </span>A plumbing company used SEO to rank higher for emergency services, leading to a 300% increase in calls and service requests.</p>
                  <p><span className='spanbold'>SaaS Company Visibility: </span>A software-as-a-service company saw a dramatic boost in their website visits and demo requests by optimizing their blog content and building quality backlinks.</p>
                  <p><span className='spanbold'>Personal Brand Growth: </span>An entrepreneur increased their personal brand visibility through strategic SEO, leading to more speaking engagements and consulting opportunities.</p>
                  <p><span className='spanbold'>Conclusion:</span> Prioritizing UI/UX design in web and mobile development can lead to numerous benefits, from improved user satisfaction to higher conversion rates. At IT Power Systems, we excel in creating user-centric designs that enhance the overall user experience and drive business success.</p>
                 </div>
            </div>
            <div className="col-xl-5 col-lg-5 col-md-12 col-sm-12 col-12" data-aos="fade-left" data-aos-duration="3000">
              <div className="recent-blogs">
                <h2>Recent Posts</h2>
                <Link to="/uiux-blogdetail" className="recent-blog">
                    <img src="assets/images/blogs/The-Importance-of-UIUX-Design.jpg" alt="" />
                    <div>
                        <h3>The Importance Of UI/UX Design In Web And Mobile Development</h3>
                        {/* <h4>September 26, 2023</h4> */}
                        <p>UI/UX design plays a crucial role in the success of web and mobile applications. It directly....</p>
                    </div>
                </Link>
                <Link to="/digital-marketing-BlogDetail" className="recent-blog">
                    <img src="assets/images/blogs/Top-5-Digital-Marketing-Mistakes.jpg" alt="" />
                    <div>
                        <h3>Top 5 Digital Marketing Mistakes and How to Avoid Them</h3>
                        {/* <h4>September 26, 2023</h4> */}
                        <p>Digital marketing is a dynamic field that requires continuous learning and adaptation....</p>
                    </div>
                </Link>
                <Link to="/mobile-app-blogdetail" className="recent-blog">
                    <img src="assets/images/blogs/The-Benefits-of-Mobile-App.jpg" alt="" />
                    <div>
                        <h3>The Benefits of Mobile App Development for Startups</h3>
                        {/* <h4>September 26, 2023</h4> */}
                        <p>In today's digital age, having a mobile app can be a game-changer for startups. It provides a direct channel to engage.....</p>
                    </div>
                </Link>
                <Link to="/social-media-blogdetail" className="recent-blog">
                    <img src="assets/images/blogs/Maximizing-ROI-with-Social-Media-Marketing.jpg" alt="" />
                    <div>
                        <h3>Maximizing ROI with Social Media Marketing: Strategies for 2024</h3>
                        {/* <h4>September 26, 2023</h4> */}
                        <p>Social media marketing is a powerful tool for maximizing return on investment (ROI).....</p>
                    </div>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
      </>
    );
}