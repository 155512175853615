import React, { useEffect } from 'react';
import { Link } from "react-router-dom";
import EcommerceWebsite from '../utils/EcommerceWebsite';
import service1 from '../assets/img/Services/graphicdesign.jpg';
import AOS from 'aos';
import 'aos/dist/aos.css';
import Card from './Card';
import { Helmet } from 'react-helmet';

export default function GraphicDesignService(){
      useEffect(() => {
        // Initialize AOS
        AOS.init();
    }, []);
    return(
      <>
      <Helmet>
        <title>IT POWER SYSTEMS - Ecommerce Web Solutions</title>
        <meta name="description" content="Grow your online store with scalable ecommerce web solutions. We design and develop platforms that enhance customer experience and boost sales - Ecommerce Web Solutions" />
      </Helmet>
      <section className="inner-banner e-commerce-banner">
      <div className="banner-content">
        <div className="container">
          <div className="row banner-b justify-content-center">
            <div className="col-xxl-7 col-xl-7 col-lg-7 col-md-7 col-sm-12 col-12 position-relative">
              <h2 className="theme-h2" data-aos="fade-right" data-aos-duration="3000"><span>E-Commerce Solutions</span></h2>
              <p data-aos="fade-right" data-aos-duration="3000"> <Link to="/" >Home</Link> <i className="fa-solid fa-chevron-right"></i> E-Commerce Solutions</p>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section className="about-sec inner-about inner-serv">
        <div className="container">
          <div className="row justify-content-center gy-4">
            <div className="col-xl- col-lg-8 col-md-12 col-sm-12 col-12" data-aos="fade-right" data-aos-duration="3000">
              <div>
                <img className='img-fluid' src='assets/images/e-commerce-img.jpg' />
              </div>
              <div className="content"> 
                <h2 className="theme-h2">E-Commerce Solutions Services</h2>
                <p>IT Power Systems offers tailored e-commerce solutions designed to help businesses excel in the online marketplace. From platform setup and custom development to ongoing optimization, we create e-commerce sites that are visually appealing, secure, and optimized for conversions. Our goal is to provide a seamless shopping experience that drives customer satisfaction and boosts sales.</p>
                <p>We specialize in Shopify and WooCommerce platforms, delivering solutions that align with your unique business needs. By focusing on functionality and user experience, we help you build a robust online presence that enhances your digital footprint.</p>
                <h2 className="theme-h2">Services We Provide:</h2>
                <ul>
                  <li>E-Commerce Platform Setup (Shopify, WooCommerce)</li>
                  <li>Custom E-Commerce Development</li>
                  <li>Payment Gateway Integration</li>
                  <li>E-Commerce SEO and Marketing</li>
                  <li>Maintenance and Support</li>
                </ul>
                <h2 className="theme-h2">Why We Are the Best: </h2>
                <p>With extensive experience in creating successful e-commerce websites, we leverage the latest technologies and best practices to build sites that are both aesthetically pleasing and highly functional. Partner with us for a seamless, profitable e-commerce solution.</p>
                <p>Ready to transform your online store? Contact IT Power Systems today to get started with our expert e-commerce solutions.</p>
              </div>
            </div>
            <div className="col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12" data-aos="fade-left" data-aos-duration="3000">
              <div className="ser-box">
                <img src="assets/images/services-details-icon-01.png" alt="logo design service" className="img-fluid"/>
                <h2 className="theme-h2">Don't hesitate to contact us</h2>
                <p>At our IT solution company, we are committed to exceptional</p>
                <Link className="theme-btn" to="/contact-us">Get In Touch </Link>
               </div>
            </div>
          </div>
        </div>
      </section>
      <section className="pricing py-5">
        <div className="container">
          <div className="row">
            <div className='text-center pb-5 text-white'>
              <h1 className='pb-2' style={{ fontWeight: 'bold' }}>Choose your right plan!</h1>
              <p className='text-white'>Select from best plan, ensuring a perfect match, Need more or less?</p>
              <p className='text-white pb-2'>Customize Your subscription for a seamless fit!</p>
            </div>
            {
              EcommerceWebsite?.map((obj) => { return <Card data={obj} /> })   // store data in myPrices map to card 
            }
          </div>
        </div>
      </section>
      <section className="ctabanner" >
          <div className="container">
              <div className="box-cta" data-aos="fade-up" data-aos-duration="3000">
              <div className="row justify-content-center align-items-center">
                  <div className="col-12 col-sm-12 col-md-11 col-lg-12 col-xl-11 col-xxl-11">
                  <div className="row align-items-center">
                      <div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 col-xxl-6">
                      <div className="ctatext">
                      <p>IT Power Systems,</p>
                      <h3>Your business can reach its full potential.</h3>
                      </div>
                      </div>
                      <div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 col-xxl-6">
                      <div className="calllsit">
                          <div className=" marginleft">
                          <Link className="theme-btn" to="/contact-us">Contact Us </Link>
                          </div>
                      </div>
                      </div>
                  </div>
                  </div>
              </div>
              </div>
          </div>
      </section>
      </>
    );
}