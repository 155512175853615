import React, { useEffect } from 'react';
import { Link } from "react-router-dom";
import websiteDevelopmentPrices from '../utils/websiteDevelopment';
import service1 from '../assets/img/Services/graphicdesign.jpg';
import AOS from 'aos';
import Card from './Card';
import 'aos/dist/aos.css';
import { Helmet } from 'react-helmet';

export default function GraphicDesignService(){
      useEffect(() => {
        // Initialize AOS
        AOS.init();
    }, []);
    return(
      <>
      <Helmet>
        <title>IT POWER SYSTEMS - Web Development</title>
        <meta name="description" content="Transform your online presence with custom web development services. We build responsive, user-friendly websites that drive traffic and conversions - Web Development" />
      </Helmet>
      <section className="inner-banner website">
      <div className="banner-content">
        <div className="container">
          <div className="row banner-b justify-content-center">
            <div className="col-xxl-7 col-xl-7 col-lg-7 col-md-7 col-sm-12 col-12 position-relative">
              <h2 className="theme-h2" data-aos="fade-right" data-aos-duration="3000"><span>Website Development</span></h2>
              <p data-aos="fade-right" data-aos-duration="3000"> <Link to="/" >Home</Link> <i className="fa-solid fa-chevron-right"></i> Website Development</p>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section className="about-sec inner-about inner-serv">
        <div className="container">
          <div className="row justify-content-center gy-4">
            <div className="col-xl- col-lg-8 col-md-12 col-sm-12 col-12" data-aos="fade-right" data-aos-duration="3000">
              <div>
                <img className='img-fluid' src='assets/images/website-dev.jpg' />
              </div>
              <div className="content"> 
                <h2 className="theme-h2">Website Development Services</h2>
                <p>At IT Power Systems, we specialize in professional website development services designed to build and strengthen your online presence. Our expertise lies in creating responsive, user-friendly websites that are not only visually appealing but also drive tangible results. From initial design concepts to seamless deployment and ongoing maintenance, our end-to-end development process ensures your website performs at its best.</p>
                <p>We utilize a range of platforms and technologies to deliver customized web solutions tailored to your business objectives. Whether you require a corporate site, an e-commerce platform, or a bespoke web application, our dedicated team ensures high-quality outcomes that enhance your digital footprint.</p>
                <h2 className="theme-h2">Services We Provide:</h2>
                <ul>
                  <li>Custom Website Design</li>
                  <li>E-Commerce Solutions</li>
                  <li>CMS Development (WordPress, Shopify)</li>
                  <li>Website Maintenance and Support</li>
                  <li>Responsive Web Design</li>
                </ul>
                <h2 className="theme-h2">Why We Are the Best: </h2>
                <p>We combine cutting-edge design with technical expertise to create websites that not only stand out but also align with your business goals. By understanding your unique needs, we develop sites optimized for both performance and user experience. From concept to launch, our focus is on delivering a website that drives engagement and conversions.</p>
                <p>Transform your online presence with our expert website development services. Contact IT Power Systems today to start your project and take your digital strategy to the next level.</p>
              </div>
            </div>
            <div className="col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12" data-aos="fade-left" data-aos-duration="3000">
              <div className="ser-box">
                <img src="assets/images/services-details-icon-01.png" alt="logo design service" className="img-fluid"/>
                <h2 className="theme-h2">Don't hesitate to contact us</h2>
                <p>At our IT solution company, we are committed to exceptional</p>
                <Link className="theme-btn" to="/contact-us">Get In Touch </Link>
               </div>
            </div>
          </div>
        </div>
      </section>
      <section className="pricing py-5">
        <div className="container">
          <div className="row">
            <div className='text-center pb-5 text-white'>
              <h1 className='pb-2' style={{ fontWeight: 'bold' }}>Choose your right plan!</h1>
              <p className='text-white'>Select from best plan, ensuring a perfect match, Need more or less?</p>
              <p className='text-white pb-2'>Customize Your subscription for a seamless fit!</p>
            </div>
            {
              websiteDevelopmentPrices?.map((obj) => { return <Card data={obj} /> })   // store data in myPrices map to card 
            }
          </div>
        </div>
      </section>
      <section className="ctabanner" >
          <div className="container">
              <div className="box-cta" data-aos="fade-up" data-aos-duration="3000">
              <div className="row justify-content-center align-items-center">
                  <div className="col-12 col-sm-12 col-md-11 col-lg-12 col-xl-11 col-xxl-11">
                  <div className="row align-items-center">
                      <div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 col-xxl-6">
                      <div className="ctatext">
                      <p>IT Power Systems,</p>
                      <h3>Your business can reach its full potential.</h3>
                      </div>
                      </div>
                      <div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 col-xxl-6">
                      <div className="calllsit">
                          <div className=" marginleft">
                          <Link className="theme-btn" to="/contact-us">Contact Us </Link>
                          </div>
                      </div>
                      </div>
                  </div>
                  </div>
              </div>
              </div>
          </div>
      </section>
      </>
    );
}