import React, { useEffect } from 'react';
import { Link } from "react-router-dom";
import PortfolioSec from "./PortfolioSec";
import AOS from 'aos';
import 'aos/dist/aos.css';
import { Helmet } from 'react-helmet';

export default function () {
  useEffect(() => {
    // Initialize AOS
    AOS.init();
  }, []);
  return (
    <>
    <Helmet>
        <title>IT POWER SYSTEMS - Our Portfolio</title>
        <meta name="description" content="View our portfolio showcasing successful projects in web development, mobile apps, and digital marketing. See how IT Power Systems drives innovation and results - Our Portfolio" />
    </Helmet>
      <section className="inner-banner portfolio-banner">
      <div className="banner-content">
        <div className="container">
          <div className="row banner-b justify-content-center">
            <div className="col-xxl-7 col-xl-7 col-lg-7 col-md-7 col-sm-12 col-12 position-relative">
              <h2 className="theme-h2" data-aos="fade-right" data-aos-duration="3000"><span>Our Portfolio</span></h2>
              <p data-aos="fade-right" data-aos-duration="3000"> <Link to="/" >Home</Link> <i className="fa-solid fa-chevron-right"></i> Our Portfolio</p>
            </div>
          </div>
        </div>
      </div>
    </section>
      <PortfolioSec />
    </>
  );
}