import React, { useEffect } from 'react';
import { Link } from "react-router-dom";
import UiUxDesign from '../utils/UiUxDesign';
import service1 from '../assets/img/Services/graphicdesign.jpg';
import AOS from 'aos';
import 'aos/dist/aos.css';
import Card from './Card';
import { Helmet } from 'react-helmet';

export default function GraphicDesignService(){
      useEffect(() => {
        // Initialize AOS
        AOS.init();
    }, []);
    return(
      <>
      <Helmet>
        <title>IT POWER SYSTEMS - UI/UX Design</title>
        <meta name="description" content="Enhance user satisfaction with intuitive UI/UX design. We create engaging, user-centric interfaces that improve functionality and user experience - UI/UX Design" />
      </Helmet>
      <section className="inner-banner uiux">
      <div className="banner-content">
        <div className="container">
          <div className="row banner-b justify-content-center">
            <div className="col-xxl-7 col-xl-7 col-lg-7 col-md-7 col-sm-12 col-12 position-relative">
              <h2 className="theme-h2" data-aos="fade-right" data-aos-duration="3000"><span>UI/UX Design</span></h2>
              <p data-aos="fade-right" data-aos-duration="3000"> <Link to="/" >Home</Link> <i className="fa-solid fa-chevron-right"></i> UI/UX Design</p>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section className="about-sec inner-about inner-serv">
        <div className="container">
          <div className="row justify-content-center gy-4">
            <div className="col-xl- col-lg-8 col-md-12 col-sm-12 col-12" data-aos="fade-right" data-aos-duration="3000">
              <div>
                <img className='img-fluid' src='assets/images/ui-ux-img.jpg'/>
              </div>
              <div className="content"> 
                <h2 className="theme-h2">UI/UX Design</h2>
                <p>At IT Power Systems, we deliver exceptional UI/UX design services focused on creating intuitive and engaging user experiences. Our approach centers on understanding user behavior to craft interfaces that are both visually stunning and easy to navigate. We ensure that every aspect of your digital product aligns with your brand identity and enhances overall user satisfaction.</p>
                <p>By prioritizing user-centered design principles, we create seamless experiences that drive user engagement and retention. Our custom UI/UX design solutions are tailored to meet your specific needs, ensuring that your digital presence is both functional and aesthetically pleasing.</p>
                <h2 className="theme-h2">Services We Provide:</h2>
                <ul>
                  <li>User Research and Personas</li>
                  <li>Wireframing and Prototyping</li>
                  <li>UI Design</li>
                  <li>UX Design</li>
                  <li>Usability Testing</li>
                </ul>
                <h2 className="theme-h2">Why We Are the Best: </h2>
                <p>Our design team blends creativity with a deep understanding of user behavior to create interfaces that are both beautiful and highly functional. We focus on optimizing the user experience at every stage, ensuring that your digital products are not only effective but also enjoyable to use.</p>
                <p>Ready to enhance your digital products? Contact IT Power Systems today for expert UI/UX design services that create delightful user experiences.</p>
              </div>
            </div>
            <div className="col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12" data-aos="fade-left" data-aos-duration="3000">
              <div className="ser-box">
                <img src="assets/images/services-details-icon-01.png" alt="logo design service" className="img-fluid"/>
                <h2 className="theme-h2">Don't hesitate to contact us</h2>
                <p>At our IT solution company, we are committed to exceptional</p>
                <Link className="theme-btn" to="/contact-us">Get In Touch </Link>
               </div>
            </div>
          </div>
        </div>
      </section>
      <section className="pricing py-5">
        <div className="container">
          <div className="row">
            <div className='text-center pb-5 text-white'>
              <h1 className='pb-2' style={{ fontWeight: 'bold' }}>Choose your right plan!</h1>
              <p className='text-white'>Select from best plan, ensuring a perfect match, Need more or less?</p>
              <p className='text-white pb-2'>Customize Your subscription for a seamless fit!</p>
            </div>
            {
              UiUxDesign?.map((obj) => { return <Card data={obj} /> })   // store data in myPrices map to card 
            }
          </div>
        </div>
      </section>
      <section className="ctabanner" >
          <div className="container">
              <div className="box-cta" data-aos="fade-up" data-aos-duration="3000">
              <div className="row justify-content-center align-items-center">
                  <div className="col-12 col-sm-12 col-md-11 col-lg-12 col-xl-11 col-xxl-11">
                  <div className="row align-items-center">
                      <div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 col-xxl-6">
                      <div className="ctatext">
                      <p>IT Power Systems,</p>
                      <h3>Your business can reach its full potential.</h3>
                      </div>
                      </div>
                      <div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 col-xxl-6">
                      <div className="calllsit">
                          <div className=" marginleft">
                          <Link className="theme-btn" to="/contact-us">Contact Us </Link>
                          </div>
                      </div>
                      </div>
                  </div>
                  </div>
              </div>
              </div>
          </div>
      </section>
      </>
    );
}