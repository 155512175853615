import React, { useEffect } from 'react';
import { Link } from "react-router-dom";
import AOS from 'aos';
import 'aos/dist/aos.css';

export default function Privacy() {
    useEffect(() => {
      // Initialize AOS
      AOS.init();
  }, []);
  return (
    <>
      <section className="inner-banner">
        <div className="banner-content">
          <div className="container">
            <div className="row banner-b justify-content-center">
              <div className="col-xxl-7 col-xl-7 col-lg-7 col-md-7 col-sm-12 col-12 position-relative">
                <h2 className="theme-h2" data-aos="fade-right" data-aos-duration="3000"><span>Privacy Policy</span></h2>
                <p data-aos="fade-right" data-aos-duration="3000"> <Link to="/" >Home</Link> <i className="fa-solid fa-chevron-right"></i> Privacy Policy</p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="privacy-section all-section">
        <div className="container">
          <div className="row">
            <div className="col-md-10 mx-auto">
              <div className="section-top text-center">
                <h3 className="theme-h2">
                  Privacy <span>Policy</span>
                </h3>
                <p>
                  This Privacy Policy highlights how IT Power Systems collects, uses,
                  maintains, and discloses information collected from every
                  user. This privacy policy applies to the Site and all products
                  and services offered by IT Power Systems.
                </p>
              </div>
            </div>
          </div>

          <div className="privacy-content">
            <span className="section-head">The Information We Access</span>
            <p>
              We may collect personal identification information from Users in a
              variety of ways, including, but not limited to, when Users visit
              our site, register on the site, place an order, subscribe to the
              newsletter, respond to a survey, fill out a form, and in
              connection with other activities, services, features or resources
              we make available on our Site. Users may be asked for, as
              appropriate, name, email address, mailing address, phone number,
              credit card information. Users may, however, visit our Site
              anonymously. We will collect personal identification information
              from Users only if they voluntarily submit such information to us.
              Users can always refuse to supply personally identification
              information, except that it may prevent them from engaging in
              certain Site related activities.
            </p>
            <span className="section-head">
              Non-Personal Identification Information
            </span>
            <p>
              We may collect non-personal identification information about Users
              whenever they interact with our Site. Non-personal identification
              information may include the browser name, the type of computer,
              and technical information about Users' means of connection to our
              Sites, such as the operating system and the Internet service
              providers utilized and other similar information.
            </p>
            <span className="section-head">Web Browser Cookies</span>
            <p>
              Our Site may use "cookies" to enhance the User experience. User's
              web browser places cookies on their hard drive for record-keeping
              purposes and sometimes to track information about them. Users may
              choose to set their web browser to refuse cookies or to alert you
              when cookies are being sent. If they do so, note that some parts
              of the Site may not function properly.
            </p>
            <span className="section-head">Why we collect information</span>
            <p>
              IT Power Systems may collect and use Users personal information for the
              following purposes:
            </p>
            <ul>
              <li>
                <p>
                  To improve customer service by knowing needs more efficiently
                </p>
              </li>
              <li>
                <p>To personalize user experience</p>
              </li>
              <li>
                <p>
                  We may use information in the aggregate to understand how our
                  Users as a group use the services and resources provided on
                  our Site
                </p>
              </li>
              <li>
                <p>To improve our Site</p>
              </li>
              <li>
                <p>
                  We may use the feedback you provide to improve our products
                  and services.
                </p>
              </li>
              <li>
                <p>To process payments </p>
              </li>
              <li>
                <p>
                  To run a promotion, contest, survey, or other Site feature
                </p>
              </li>
              <li>
                <p>
                  To send User’s information they agreed to receive about topics
                  we think will be of interest to them
                </p>
              </li>
              <li>
                <p>To send periodic emails</p>
              </li>
            </ul>
            <span className="section-head">How We Protect Your Information</span>
            <p>
              We adopt appropriate data collection, storage, and processing
              practices and security measures to protect against unauthorized
              access, alteration, disclosure, or destruction of your personal
              information, username, password, transaction information, and data
              stored on our Site.
            </p>
            <span className="section-head">Sharing Your Personal Information</span>
            <p>
              We do not sell, trade, or rent Users' personal identification
              information to others. We may share generic aggregated demographic
              information not linked to any personal identification information
              regarding visitors and users with our business partners, trusted
              affiliates, and advertisers for the purposes outlined above. We
              may use third-party service providers to help us operate our
              business and the Site or administer activities on our behalf, such
              as sending out newsletters or surveys. We may share your
              information with these third parties for those limited purposes
              provided that you have given us your permission.
            </p>
            <span className="section-head">Changes to This Privacy Policy</span>
            <p>
              IT Power Systems has the discretion to update this privacy policy at
              any time. When we do, we will revise the updated date at the
              bottom of this page. We encourage Users to frequently check this
              page for any changes to stay informed about how we are helping to
              protect the personal information we collect. You acknowledge and
              agree that it is your responsibility to review this privacy policy
              periodically and become aware of modifications.
            </p>
            <span className="section-head">Your Acceptance of These Terms</span>
            <p>
              By using this Site, you signify your acceptance of this policy. If
              you do not agree to this policy, please do not use our Site. Your
              continued use of the Site following the posting of changes to this
              policy will be deemed your acceptance of those changes.
            </p>
          </div>
        </div>
      </section>
    </>
  );
}
