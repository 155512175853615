import React, { useEffect } from 'react';
import { Link } from "react-router-dom";
import service1 from '../assets/img/Services/graphicdesign.jpg';
import AOS from 'aos';
import 'aos/dist/aos.css';

export default function GraphicDesignService(){
      useEffect(() => {
        // Initialize AOS
        AOS.init();
    }, []);
    return(
      <>
      <section className="inner-banner">
      <div className="banner-content">
        <div className="container">
          <div className="row banner-b justify-content-center">
            <div className="col-xxl-7 col-xl-7 col-lg-7 col-md-7 col-sm-12 col-12 position-relative">
              <h2 className="theme-h2" data-aos="fade-right" data-aos-duration="3000"><span>Content Writing</span></h2>
              <p data-aos="fade-right" data-aos-duration="3000"> <Link to="/" >Home</Link> <i className="fa-solid fa-chevron-right"></i> Content Writing</p>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section className="about-sec inner-about inner-serv">
        <div className="container">
          <div className="row justify-content-center gy-4">
            <div className="col-xl- col-lg-8 col-md-12 col-sm-12 col-12" data-aos="fade-right" data-aos-duration="3000">
              <div>
                <img className='img-fluid' src='assets/images/services-details-img-01.png' />
              </div>
              <div className="content"> 
                <h2 className="theme-h2">Content Writing</h2>
                <p>IT Power Systems provides comprehensive Website Development services to help businesses establish a strong online presence. We specialize in creating responsive, user-friendly, and visually appealing websites that drive results. Our development process includes everything from initial planning and design to deployment and maintenance, ensuring a seamless experience.</p>
                <p>We work with various platforms and technologies to deliver customized solutions that meet your specific business goals. Whether you need a corporate website, an e-commerce platform, or a custom web application, our team is dedicated to delivering high-quality results that enhance your digital footprint.</p>
                <h2 className="theme-h2">Services We Provide:</h2>
                <ul>
                  <li>Custom Website Design</li>
                  <li>E-Commerce Solutions</li>
                  <li>CMS Development (WordPress, Shopify)</li>
                  <li>Website Maintenance and Support</li>
                  <li>Responsive Web Design</li>
                </ul>
                <h2 className="theme-h2">Why We Are the Best: </h2>
                <p>We combine creative design with technical expertise to deliver websites that stand out. Our team works closely with you to understand your business needs and develop a site that aligns with your vision. From concept to launch, we ensure your website is optimized for performance and user experience.</p>
                <p>Call to Action: Transform your online presence with a professionally developed website. Contact IT Power Systems today to start your web development project.</p>
              </div>
            </div>
            <div className="col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12" data-aos="fade-left" data-aos-duration="3000">
              <div className="ser-box">
                <img src="assets/images/services-details-icon-01.png" alt="logo design service" className="img-fluid"/>
                <h2 className="theme-h2">Don't hesitate to contact us</h2>
                <p>At our IT solution company, we are committed to exceptional</p>
                <Link className="theme-btn" to="/contact-us">Get In Touch </Link>
               </div>
            </div>
          </div>
        </div>
      </section>
      <section className="ctabanner" >
          <div className="container">
              <div className="box-cta" data-aos="fade-up" data-aos-duration="3000">
              <div className="row justify-content-center align-items-center">
                  <div className="col-12 col-sm-12 col-md-11 col-lg-12 col-xl-11 col-xxl-11">
                  <div className="row align-items-center">
                      <div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 col-xxl-6">
                      <div className="ctatext">
                          <p>ready to get started</p>
                          <h3>Helping people organise online businesses</h3>
                      </div>
                      </div>
                      <div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 col-xxl-6">
                      <div className="calllsit">
                          <div className=" marginleft">
                          <Link className="theme-btn" to="/contact-us">Contact Us </Link>
                          </div>
                      </div>
                      </div>
                  </div>
                  </div>
              </div>
              </div>
          </div>
      </section>
      </>
    );
}