import React, { useEffect } from 'react';
import { Link } from "react-router-dom";
import PortfolioSec from "./PortfolioSec";
import AOS from 'aos';
import 'aos/dist/aos.css';
import { Helmet } from 'react-helmet';

export default function Services(){
      useEffect(() => {
        // Initialize AOS
        AOS.init();
    }, []);
    return (
      <>
      <Helmet>
        <title>IT POWER SYSTEMS - Services</title>
        <meta name="description" content="Explore our range of IT services, including custom mobile app development, digital marketing, software solutions, and more, designed to elevate your business - Services" />
      </Helmet>
      <section className="inner-banner">
        <figure>
          <video autoPlay muted loop>
            <source src="assets/images/inner-banner.mp4" alt="Banner Video" type="video/mp4"/>
            </video>
        </figure>
        <div className="banner-content">
          <div className="container">
            <div className="row banner-b">
              <div className="col-xxl-7 col-xl-7 col-lg-7 col-md-7 col-sm-12 col-12 position-relative">
                <h4 className="theme-h4" data-aos="fade-right" data-aos-duration="3000">Unique Logo Designs</h4>
                <h2 className="theme-h2" data-aos="fade-right" data-aos-duration="3000">transforming vision into attractive <span>logo</span> designs</h2>
                <p data-aos="fade-right" data-aos-duration="3000">Combining artistic flair with a deep understanding of your brand's values and target audience
                  to deliver logos that are visually pleasing to the eyes.</p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="about-sec inner-about">
          <div className="container">
            <div className="row justfy-content-center align-items-center gy-4">
              <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12" data-aos="fade-right" data-aos-duration="3000">
                <div className="content">
                  <h4 className="theme-h4">Custom logo design service</h4>
                  <h2 className="theme-h2">our <span>systematic</span> approach</h2>
                  <p>We understand the importance of a precise and scientific approach to the logo design
                    process. Our team of architects, thinkers, and artists breaks down each project into
                    manageable chunks, allowing for clarity and creativity to flourish. Our designers
                    have developed an effective design strategy through countless hours of dedicated
                    work. We recognize the challenges of embarking on a design journey without a clear
                    roadmap.
                  </p>

                  <div className="btns-about">
                    <Link className="theme-btn" to="/contact-us">Get Started </Link>
                    <a className="theme-btn-2" href="javascipt:;">Live chat </a>
                  </div>
                </div>
              </div>
              <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12" data-aos="fade-left" data-aos-duration="3000">
                <div className="img-ab">
                  <img src="assets/images/logo-secnd.webp" alt="logo design service" className="img-fluid"/>
                 </div>
              </div>
            </div>
          </div>
        </section>
        <section className="ctabanner" data-aos="fade-up" data-aos-duration="3000">
          <div className="container">
            <div className="box-cta">
              <div className="row justify-content-center">
                <div className="col-12 col-sm-12 col-md-11 col-lg-12 col-xl-11 col-xxl-11">
                  <div className="row align-items-center">
                    <div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 col-xxl-6">
                      <div className="ctatext">
                        <h3>are you ready to <span>collaborate</span> with us?</h3>
                        <p>Let’s get on a ride where enormous success is coming our way!</p>
                      </div>
                    </div>
                    <div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 col-xxl-6">
                      <div className="calllsit">
                        <h4>Give Us Call</h4>
                        <a className="num" href="tel:+1 571-622-0760">+1 571-622-0760</a>
                        <div className=" marginleft">
                          <Link className="theme-btn" to="/contact-us">Request A Quote </Link>
                          <a href="javascript:;Tawk_API.toggle();" className="theme-btn-2">Live
                            Chat</a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        
        </>
    
    );
}